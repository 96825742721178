
export const routes = {
  home: '/',
  hotels: '/hotels',
  hotels_payment_success: '/hotels/payment/success',
  hotels_payment_error: '/hotels/payment/error',
  hotels_booking: '/hotels/booking',
  feedback : '/feedback',
  help: '/help',
  no_results:'/no_results',
  search:'/search',
  lkPassengers: '/lk/passengers/',
  lkPassengersNew: '/lk/passengers/new',
  any: '/*',
  badGateway: '/502',
  unknownPage:'/404',
  pay_stub:'/pay_stub',
  partnership: '/partnership',
  tariff: '/tariff',
  rules:'/rules',
  blog: '/blog',
  register: '/register',
  passwordRecover: '/password-recover',
  booking:'/booking',
  buying:'/buying',
  lk_profile: '/lk/profile',
  lk_orders: '/lk/orders',
  lk_passengers: '/lk/passengers',
  lk_partnership: '/lk/partnership',
  lk_partnership_payouts: '/lk/partnership/payouts',
  safety_return_policy: '/safety_return_policy',
  confedential_policy: '/confedential_policy',
  lk: '/lk',
  blogId: "/blog/:id",
  lkPartnershipId: "/lk/partnership/:id",
  lkPassengersId: "/lk/passengers/:id",
  lkOrdersId: '/lk/orders/:id',
  lkOrdersFailId: '/lk/orders/fail/:id',
  lkOrdersSuccessId: '/lk/orders/success/:id',
  ordersSuccess: '/orders/success',
  ordersFail: '/orders/fail',
}
