import { FunctionComponent, useEffect, useState } from 'react';
import clsx from 'clsx';
import Button from '../Button';
import RangeDatePicker from '../Input/RangeDatePicker/RangeDatePicker';
import PassengersSelect from '../Input/PassengersSelect/PassengersSelect';
import styles from './SearchBar.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import Autocomplete from '../Autocomplete/Autocomplete';
import { useSelector } from 'react-redux';
import {
  saveBackendData,
  swapRoutes,
  searchSelector,
  setPassengers as setSearchPassengers,
  SearchTypeEnum,
  setLoading,
  resetFilters,
  resetSearchData,
  setSearch,
  setType,
  filterBackendData,
} from '../../store/slices/search';
import { dateRangeSelector, setFromTo } from '../../store/slices/datePicker';
import { useLazySearchQuery } from '../../store/api/searchService';
import { useAppDispatch } from '../../store/store';
import { Passenger } from '../../models/Passenger';
import { setPassengers, setPreviewOrder } from '../../store/slices/booking';
import { dateBackendAdapter, getPassenger, parseDate } from '../../utils/adapters';
import { format } from 'date-fns';
import {enUS, ru} from 'date-fns/locale';
import { getRightEndingPassengers } from '../../utils/formatting';
import { IPassenger } from '../../types/passenger';
import { userSelector } from '../../store/slices/user';
import { useGetPassengersQuery } from '../../store/api/userService';
import useIsMobile from '../../utils/useIsMobile';
import { Airport, City } from '../../types/ticket';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import { routes } from '../../constants/routes';
import { useLangNavigate } from '../../utils/langNavigate';
import {updateModal} from "../../store/slices/modal";
import {useGetBookingDataQuery} from "../../store/api/settingsService";

/**
 * find passengers of corrent type from user's passengers or create new;
 */
const createPassengers = (
  { adults, children, babies }: { adults: number; children: number; babies: number },
  userPassengers: IPassenger[]
) => {
  const _passengers = [...userPassengers];
  const adultPassengers = new Array(adults)
    .fill(0)
    .map(() => getPassenger(_passengers, new Passenger({ type: 'adult' }).getPassenger()));
  const childPassengers = new Array(children)
    .fill(0)
    .map(() => getPassenger(_passengers, new Passenger({ type: 'child' }).getPassenger()));
  const babyPassengers = new Array(babies)
    .fill(0)
    .map(() => getPassenger(_passengers, new Passenger({ type: 'baby' }).getPassenger()));

  const r = [...adultPassengers, ...childPassengers, ...babyPassengers];
  let result: IPassenger[] = [];
  r.map((p, index) => {
    let clone = Object.assign({}, p);
    clone.id = index + 1;
    result.push(clone);
  });
  return result;
};

interface Props {
  className?: string;
  defaultOpen?: boolean;
}

const SearchBar: FunctionComponent<Props> = ({ className, defaultOpen = false }) => {
  const href = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [fromError, setFromError] = useState('');
  const [toError, setToError] = useState('');
  const [dateError, setDateError] = useState('');
  const [loaded, setPageLoaded] = useState(false);
  const { from, to, adults, children, babies, type } = useSelector(searchSelector);
  const { from: dateFrom, to: dateTo } = useSelector(dateRangeSelector);
  const { user, token } = useSelector(userSelector);
  const { data: userPassengers } = useGetPassengersQuery({ token }, { skip: !token });
  const [search] = useLazySearchQuery();
  const isMobile = useIsMobile(1025);
  const saved = localStorage.getItem('search_data');
  const langNavigate = useLangNavigate();
  const { data: bookingSettings } = useGetBookingDataQuery({});

  const { t } = useTranslation();

  const SearchTypeEnum = {
    // 'any': 'Любой',
    'eco': t('searchTypeEnum.eco'),
    'biz': t('searchTypeEnum.biz'),
  };

  useEffect(() => {
    if (!loaded) {
      if (href.pathname.includes('/search')) {
        if (saved) {
          const fields = JSON.parse(saved);
          dispatch(setSearch({ from: fields.from, to: fields.to }));
          dispatch(setFromTo({ from: fields.date ?? null, to: fields.date_back ?? null }));
          dispatch(setType({ type: fields.type }));
          dispatch(setSearchPassengers({ adults: fields.adults, children: fields.children, babies: fields.babies }));
          if (fields.filters) {
            for (const [key, value] of Object.entries(fields.filters)) {
              dispatch(filterBackendData({ key, value }));
            }
          }

          const tickets = localStorage.getItem('tickets_data');
          if (tickets) {
            dispatch(saveBackendData({ data: JSON.parse(tickets) }));
          }
        }
      }
      setPageLoaded(true);
    }
  }, [saved, loaded]);

  const expandMobile = () => {
    setIsOpen(true);
  };

  const checkOnSameCities = (from: Airport | City | null, to: Airport | City | null) => {
    if (to === null && from === null) {
      return false;
    }

    if (from?.code === to?.code) {
      return true;
    }

    const from_city: any = from?.city ? from?.city : from?.name;
    const to_city: any = to?.city ? to?.city : to?.name;

    if (from_city === to_city) {
      return true;
    }

    return false;
  };

  const handleSearch = () => {
    if (bookingSettings?.data?.happy_new_year_popup) {
        dispatch(updateModal({ happyNewYear: true }));
    }

    if (!from) {
      setFromError(t('searchBar.selectDepartureCity'));
    }
    if (!to) {
      setToError(t('searchBar.selectYourArrivalCity'));
    }
    if (!dateFrom) {
      setDateError(t('searchBar.selectDate'));
    }
    if (checkOnSameCities(from, to)) {
      setToError(t('searchBar.selectAnotherArrivalCity'));
      return;
    }
    if (!from || !to || !dateFrom) return;
    setIsOpen(false);
    // let __passengers = userPassengers ? (user && user.user_id > 0 && user.id > 0 ? [user, ...userPassengers] : [...userPassengers]) : [user];

    dispatch(resetFilters());
    dispatch(resetSearchData());
    dispatch(setPreviewOrder({ order: null }));
    const bookingPassengers = createPassengers({ adults, children, babies }, []);
    localStorage.setItem('booking_passengers', JSON.stringify(bookingPassengers));
    dispatch(setPassengers({ passengers: bookingPassengers }));
    dispatch(setLoading({ value: true }));
    // сохраняем данные поиска в Local Storage
    localStorage.setItem(
      'search_data',
      JSON.stringify({
        from,
        to,
        date: dateFrom,
        date_back: dateTo,
        adults,
        children,
        babies,
        type,
      })
    );
    localStorage.removeItem('ticket_data');
    localStorage.removeItem('tickets_data');
    localStorage.removeItem('preview_ticket');
    localStorage.removeItem('booking_data');
    search(
      {
        airport_from: from.code,
        airport_to: to.code,
        date: dateBackendAdapter(parseDate(dateFrom)),
        date_back: dateBackendAdapter(parseDate(dateTo)),
        adults,
        children,
        babies,
        type,
      },
      false
    )
      .unwrap()
      .then(data => {
        dispatch(saveBackendData({ data }));
        localStorage.setItem('tickets_data', JSON.stringify(data));
      })
      .catch(e => {
        dispatch(setLoading({ value: false }));
        console.error(e);
      });

      langNavigate(routes.search);
  };

  const handleSwapRoutes = () => {
    const container = document.querySelector(isMobile ? '.autocomplete-mobile' : '.autocomplete-desktop') ?? null;
    if (container) {
      let first = container?.querySelector('.autocomplete-wrapper-from')?.querySelector('input');
      let second = container?.querySelector('.autocomplete-wrapper-to')?.querySelector('input');
      if (first && second) {
        const copy = first.value;
        first.value = second.value;
        second.value = copy;
      }
    }
    dispatch(swapRoutes());
  };

  const passengersNumber = adults + children + babies;
  const flightType = type ? SearchTypeEnum[type] ?? t('searchBar.any') : 'NULL';

  const searchItems = (
    <>
      <div className={styles.searchItem}>
        <Autocomplete
          name="from"
          label={t('main.searchBar.labels.from')}
          error={fromError}
          clearError={() => setFromError('')}
        />
      </div>
      <div className={styles.divider + ' ' + styles.hiddenInMobile} />
      <div className={styles.swipeButton}>
        <button onClick={handleSwapRoutes} disabled={from === null && to === null}>
          <img src="/icons/swipe-arrows.svg" alt="icon" />
        </button>
      </div>
      <div className={styles.divider + ' ' + styles.hiddenInMobile} />
      <div className={styles.searchItem}>
        <Autocomplete
          name="to"
          label={t('main.searchBar.labels.to')}
          error={toError}
          clearError={() => setToError('')}
        />
      </div>
      <div className={styles.divider} />
      <div className={clsx(styles.searchItem, styles.datepicker)}>
        <RangeDatePicker
          labelFrom={t('main.searchBar.labels.flightDate')}
          labelTo={t('main.searchBar.labels.returnDate')}
          error={dateError}
          clearError={() => setDateError('')}
        />
      </div>
      <div className={styles.divider} />
      <div className={styles.searchItem}>
        <PassengersSelect />
      </div>
    </>
  );

  const isRussianLanguage = i18n.language.startsWith('ru');

  return (
    <div className={clsx(styles.searchField, className)}>
      <div className={styles.searchDesktop + ' autocomplete-desktop'}>{searchItems}</div>
      {!isOpen ? (
        <div className={styles.searchMobile} onClick={expandMobile}>
          <div className={styles.subtitleheading}>
            <div className={styles.div}>
              {isRussianLanguage ?
                (from?.name ?? from?.city ?? from?.code ?? t('searchBar.departure'))
                :
                (from?.name_en ?? from?.city_en ?? from?.code ?? t('searchBar.departure'))
              }
              -
              {isRussianLanguage ?
                (to?.name ?? to?.city ?? to?.code ?? t('searchBar.arrival'))
                :
                (to?.name_en ?? to?.city_en ?? to?.code ?? t('searchBar.arrival'))
              }
            </div>
            <div className={styles.div1}>
              {dateFrom ? format(dateFrom, 'dd MMMM', { locale: i18n.language.startsWith('ru') ? ru : enUS }) : t('searchBar.departureDate')} -{' '}
              {dateTo ? format(dateTo, 'dd MMMM', { locale: i18n.language.startsWith('ru') ? ru : enUS }) : t('searchBar.returnDate')},{' '}
              {getRightEndingPassengers(passengersNumber)}, {flightType}
            </div>
          </div>
          <img className={styles.icon} alt="" src={'icons/edit.svg'} />
        </div>
      ) : (
        <div className={styles.searchMobileExpand + ' autocomplete-mobile'}>{searchItems}</div>
      )}
      <Button className={styles.searchButton} onClick={handleSearch}>
        {t('common.search')}
      </Button>
      {isOpen ? (
        <Button className={styles.searchButtonMobile} onClick={handleSearch}>
          {t('common.search')}
        </Button>
      ) : null}
    </div>
  );
};

export default SearchBar;

function ndateBackendAdapter(dateFrom: number): string {
  throw new Error('Function not implemented.');
}
