import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {BASE_URL} from "../../utils/api";
import {ApiResponse} from "../../types/backend";

const basicHeaders = {Accept: 'application/json', 'Content-type': 'application/json'};

interface Scripts
{
  header: string;
  footer: string;
}

interface Booking
{
  show_block: boolean;
  pairs: any;
  happy_new_year_popup: boolean
}

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery: fetchBaseQuery({baseUrl: BASE_URL}),
  endpoints: builder => ({
    getScripts: builder.query<ApiResponse<Scripts>, {}>({
      query: ({}) => ({
        url: 'settings/scripts',
        headers: {...basicHeaders}
      }),
    }),
    getBookingData: builder.query<ApiResponse<Booking>, {}>({
      query: ({}) => ({
        url: 'settings/booking',
        headers: {...basicHeaders}
      }),
    })
  }),
});

export const { useGetScriptsQuery, useGetBookingDataQuery } = settingsApi;