import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiPaginationResponse } from '../../types/backend';
import {ILink, IOrderRefCode, IPartner, IPartnerLink, IPartnerPayout} from '../../types/partner';
import { BASE_URL, trimFields } from '../../utils/api';

const basicHeaders = { Accept: 'application/json', 'Content-type': 'application/json' };

/**
partnerApi

** поле с '?' после названия не обязательно

** в дизайне зачем-то при регистрации надо ввести место размещения ссылки.
** буду отправлять создание ссылки отдельным запросом сразу после создания пользователя

interface IPartner {
  id: number | string;
  payment: 'card' | 'phone' | 'req';
  cardNumber?: string;
  bankName?: string;
  phone?: string;
  name?: string;
  surname?: string;
  patronymic?: string;
  bik?: string;
  checkingAccount?: string;
  corpAccount?: string;
  // эти два с твоего апи
  price?: string;
  percent?: string;
}
interface IPartnerLink {
  id: number | string;
  name: string;
  url: string;
  passengersTotal?: number;
  ordersTotal?: number;
  incomeTotal?: number;
  orders?: IOrderLink[]
}

interface IOrderLink {
  id: string | number;
  name: string;   // города откуда и куда через тире
  passengers: number;
  date: number; // unix timestamp
  income: number
}

getPartner:     GET '/partner/{id}'           body: null              res: IPartner                         query: null
createPartner:  POST '/partner'               body: IPartner          res: IPartner                         query: null
updatePartner:  PATCH '/partner'              body: IPartner          res: IPartner                         query: null
generateLink:   GET '/partner/link/generate'  body: null              res: string                           query: null
createLink:     POST '/partner/link'          body: IPartnershipLink  res: IPartnershipLink                 query: null
getLinks:       GET '/partner/link'           body: null              res: Pagination<IPartnershipLink[]>   query: {limit, page, from, to, group_by}  
getLink:        GET '/partner/link/{id}'      body: null              res: null                             query: null
updateLink:     PATCH '/partner/link/{id}'    body: IPartnershipLink  res: IPartnershipLink                 query: null
deleteLink:     DELETE '/partner/link/{id}'   body: null              res: null                             query: null
setSubscriber:  POST '/partner/subscriber'    body: {link: string}    res: httpOnly cookie                  query: null

все запросы со стандартной авторизацией Authorization: `Bearer ${token}`
query-параметры {from, to} - unix timestamp 1679863288164 - пример Mar 26 2023 23:41:28
query-параметр {group_by} - возмножные значения 'week' | 'month'
 */

export const partnerApi = createApi({
  reducerPath: 'partnerApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['Links'],
  endpoints: builder => ({
    getPartner: builder.query<{ data: IPartner }, { token: string }>({
      query: ({ token }) => ({
        url: `partner`,
        headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
      }),
      keepUnusedDataFor: 3600,
    }),
    createPartner: builder.mutation<{ data: IPartner }, Omit<IPartner, 'id'> & { user_id: number; token: string }>({
      query: data => {
        const { token, ...body } = trimFields(data);
        return {
          url: `partner`,
          method: 'POST',
          headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
          body,
        };
      },
    }),
    updatePartner: builder.mutation<{ data: IPartner }, IPartner & { token: string }>({
      query: data => {
        const { token, ...body } = trimFields(data);
        return {
          url: `partner`,
          method: 'PUT',
          headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
          body,
        };
      },
    }),
    deletePartner: builder.mutation<IPartner, { token: string }>({
      query: data => {
        const { token } = data;
        return {
          url: `partner`,
          method: 'DELETE',
          headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
        };
      },
    }),
    getLinks: builder.query<
      ApiPaginationResponse<IPartnerLink>,
      { limit?: number; page?: number; date?: string; date_from?: string; date_to?: string; token: string }
    >({
      query: ({ token, date, limit, page, date_from, date_to }) => ({
        url: 'partner/link',
        params: { date, limit, page, date_from, date_to },
        headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
        providesTags: (result: ApiPaginationResponse<IPartnerLink>) =>
          result?.data.length
            ? [
                ...result.data.map(({ id }) => ({ type: 'Links' as const, id: id.toString() })),
                { type: 'Links', id: 'LIST' },
              ]
            : [{ type: 'Links', id: 'LIST' }],
      }),
    }),
    getPayouts: builder.query<
      ApiPaginationResponse<IPartnerPayout>,
      { limit?: number; page?: number; date?: string; date_from?: string; date_to?: string; token: string }
    >({
      query: ({token, date, limit, page, date_from, date_to}) => ({
          url: 'partner/payouts',
          params: {date, limit, page, date_from, date_to},
          headers: {...basicHeaders, Authorization: `Bearer ${token}`},
          providesTags: (result: ApiPaginationResponse<IPartnerLink>) =>
            result?.data.length
              ? [
                ...result.data.map(({id}) => ({type: 'Links' as const, id: id.toString()})),
                {type: 'Links', id: 'LIST'},
              ]
              : [{type: 'Links', id: 'LIST'}],
      })
    }),
    generateLink: builder.query<ILink, { token: string }>({
      query: ({ token }) => ({
        url: 'partner/link/generate',
        headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
      }),
    }),
    createLink: builder.mutation<IPartnerLink, { name: string; code: string; token: string }>({
      query: data => {
        const { token, ...body } = trimFields(data);
        return {
          url: `partner/link`,
          method: 'POST',
          headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
          body,
        };
      },
      invalidatesTags: [{ type: 'Links', id: 'LIST' }],
    }),
    getLink: builder.query<
      IPartnerLink,
      { date?: string; date_from?: string; date_to?: string; link_id?: string | number; token: string }
    >({
      query: ({ link_id, token, date, date_from, date_to }) => ({
        url: `partner/link/${link_id}`,
        params: { date, date_from, date_to },
        headers: { Accept: 'text/html', Authorization: `Bearer ${token}` },
      }),
    }),
    updateLink: builder.mutation<IPartnerLink, IPartnerLink & { token: string }>({
      query: data => {
        const { token, ...body } = trimFields(data);
        return {
          url: `partner/link/${data.id}`,
          method: 'PUT',
          headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
          body,
        };
      },
      invalidatesTags: [{ type: 'Links', id: 'LIST' }],
    }),
    deleteLink: builder.mutation<string, { id: string | number; token: string }>({
      query: data => {
        const { token, id } = trimFields(data);
        return {
          url: `partner/link/${id}`,
          method: 'DELETE',
          headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
        };
      },
      invalidatesTags: [{ type: 'Links', id: 'LIST' }],
    }),
    setSubscriber: builder.mutation<IOrderRefCode, { link: string; token: string }>({
      query: data => {
        const { token, ...body } = trimFields(data);
        return {
          url: `partner/subscriber`,
          method: 'POST',
          headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
          body,
          // credentials: 'include',
        };
      },
    }),
  }),
});
