import clsx from 'clsx';
import Card from '../../../Card/Card';
import styles from './EarningsCard.module.scss';
import React from "react";

interface Props {
  icon?: string;
  name: string;
  text: string;
  value?: number | string;
  className?: string;
}

const EarningsCard = ({ icon, name, value, className, text }: Props) => {
  return (
    <Card className={clsx(styles.root, className)}>
      <div className={styles.column}>
        <div className={styles.row}>
          <img className={styles.img} src={icon}/>
          <span className={styles.main}>{name}</span>
        </div>
        <div className={styles.text}>{text}</div>
      </div>
      <span className={styles.value}>{value}</span>
    </Card>
  );
};
export default EarningsCard;
