import clsx from 'clsx';
import { setModalText, updateModal } from '../../store/slices/modal';
import { useAppDispatch } from '../../store/store';
import Button from '../Button';
import styles from './FeedbackHotelsCard.module.scss';
import { getNormalisedDate } from '../../utils/formatting'
import { useTranslation } from 'react-i18next';
import i18n from "i18next";

export interface IFeedback {
  id: number;
  avatar?: string;
  created_at: string | null;
  name: string;
  surname: string | null;
  rating: number;
  text: string;
  text_en: string | null;
  org_comment?: string | null;
  org_comment_en?: string | null;
  org_comment_created_at?: string | null;
}

type Props = IFeedback & { showFull?: boolean, className?: string };

const FeedbackHotelsCard = (props: Props) => {
  const { created_at, name, surname, rating, text, text_en, showFull, org_comment, org_comment_en, org_comment_created_at, className } = props;
  const dispatch = useAppDispatch();
  const {t} = useTranslation()
  const handleShowFullFeedback = () => {
    dispatch(updateModal({ feedbackView: true }));
    dispatch(setModalText({ feedback: props }));
  };

  const answerMessage = (
      <div className={styles.answerWrapper}>
          <div className={styles.answerTop}>
              <div className={styles.answerHeader}>
                  <img className={styles.vectorIconTrip} alt="" src="/icons/cupiTrip.svg" />
                  <span>КупиТрип</span>
                  <img className={styles.vectorIconApproved} alt="" src="/icons/approved.svg" />
              </div>
              <div className={styles.answerDate}>{getNormalisedDate(org_comment_created_at)}</div>
          </div>
          <div>{(!i18n.language.startsWith('ru') && org_comment_en) || org_comment}</div>
      </div>
  )

  return (
    <div className={clsx(styles.feedback, showFull ? styles.noPadding : '', className)}>
      <div className={styles.photodataParent}>
        <div className={styles.photodata}>
          <div className={clsx(styles.name, showFull ? styles.showFullName : '')}>
              {`${name} ${surname ? surname.charAt(0) + '.' : ''}`}
          </div>
          {!showFull ? <div className={styles.date}>{getNormalisedDate(created_at)}</div> : null}
        </div>
        <div className={styles.ratingAnswer}>
          <div className={styles.rating}>
            <img className={styles.vectorIcon} alt="" src="/icons/star.svg" />
            <div className={styles.ratingText}>{rating}.0</div>
          </div>
          {
              org_comment
                ? <div className={clsx(styles.rating, styles.answer)}>
                  <img className={styles.vectorIcon} alt="" src="/icons/confirm.svg" />
                  <div className={styles.answerText}>{t('hotels.answer')}</div>
                </div>
                : null
          }
          {showFull ? <div className={clsx(styles.date, styles.showFullData)}>{getNormalisedDate(created_at)}</div> : null}
        </div>
      </div>
      <div className={clsx(styles.reviewText, showFull ? '' : styles.overflow)}>
        {(!i18n.language.startsWith('ru') && text_en) || text}
      </div>
      {showFull ? null : (
        <Button
          variant="text"
          className={styles.arrowFuther}
          iconAfter="/icons/read-feedback-icon-hotel.svg"
          onClick={handleShowFullFeedback}
        >
          {t('hotels.feedbackButton')}
        </Button>
      )}
      {showFull && org_comment ? answerMessage : null}
    </div>
  );
};
export default FeedbackHotelsCard;
