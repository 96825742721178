import clsx from 'clsx';
import Card from '../../../Card/Card';
import styles from './TotalCard.module.scss';

interface Props {
  icon: string;
  name: string;
  value?: number | string;
  className?: string;
  children?: any
}

const TotalCard = ({icon, name, value, className, children}: Props) => {
  return (
    <Card className={clsx(styles.root, className)}>
      <div>
        <div className={styles.row}>
          <img className={styles.img} src={icon}/>
          <span className={styles.main}>{name}</span>
        </div>
        <span className={styles.value}>{value}</span>

      </div>
      {children}
    </Card>
  );
};
export default TotalCard;
